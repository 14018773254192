import React from 'react';
import { AppWrap, MotionWrap } from '../../wrapper';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

import './Gallery.scss';

const Gallery = () => {
  const location = useLocation();

  const isHomePage = location.pathname === '/';

  return (
    <div className="gallery__container">
      {isHomePage && <h1 style={{textAlign: "center"}} className="shop__title">World Gallery</h1>}

      {!isHomePage && <h1 style={{textAlign:"center"}} className="shop__title">World Gallery</h1>}
      <div className="app__profile-text">
        <p className="p-text" style={{ marginTop: 10 }}>
          Walter Paul Bebirian is a commercial and personal photographer
          specializing in digital images in Forest Hills, NY. His unique fine
          art and artistic photography capture the essence of every subject,
          making each piece a work of contemporary art. Explore his online art
          gallery to discover a diverse range of custom art, including limited
          edition art and art prints. Invest in original art and creative
          portraits that will stand the test of time.
        </p>
        <div className="app__navbar-button" style={{ marginTop: 50 }}>
          <a href="https://walterpaul-bebirian.pixels.com/" target='_blank'>
            <motion.button
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.9 }}
              transition={{ duration: 0.3 }}
              style={{textAlign: 'center'}}
              className="header-shop-now-btn p-text"
            >
              Check it Out Now!
            </motion.button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default AppWrap(
  MotionWrap(Gallery, 'gallery__container'),
  'gallery',
  'app__primarybg',
);
