import React from 'react';
import { AppWrap, MotionWrap } from '../../wrapper';
import { motion } from 'framer-motion';
import { AiFillEye } from 'react-icons/ai';
import './Team.scss';

const salesTeam = [
  {
    name: 'Vanessa Williams',
    title: 'Lead Sales Manager',
    img: require('../../assets/sales_team1.jpg'),
    imgUrl:
      'https://walterpaul-bebirian.pixels.com/featured/df239-walter-paul-bebirian.html',
  },
  {
    name: 'Sasha Johnson',
    title: 'Sales Manager',
    img: require('../../assets/sales_team2.jpg'),
    imgUrl:
      'https://walterpaul-bebirian.pixels.com/featured/df159-walter-paul-bebirian.html',
  },
  {
    name: 'Tessa Banks',
    title: 'Sales',
    img: require('../../assets/sales_team3.jpg'),
    imgUrl:
      'https://walterpaul-bebirian.pixels.com/featured/df158-walter-paul-bebirian.html',
  },
  {
    name: 'Martha Hugus',
    title: 'Sales',
    img: require('../../assets/sales_team4.jpg'),
    imgUrl:
      'https://walterpaul-bebirian.pixels.com/featured/df157-walter-paul-bebirian.html',
  },
  {
    name: 'Jamie Worth',
    title: 'Sales',
    img: require('../../assets/sales_team5.jpg'),
    imgUrl:
      'https://walterpaul-bebirian.pixels.com/featured/df154-walter-paul-bebirian.html',
  },
  {
    name: 'Sarah Reynolds',
    title: 'Sales',
    img: require('../../assets/sales_team6.jpg'),
    imgUrl:
      'https://walterpaul-bebirian.pixels.com/featured/df155-walter-paul-bebirian.html',
  },
  {
    name: 'Mia West',
    title: 'Sales',
    img: require('../../assets/sales_team7.jpg'),
    imgUrl:
      'https://walterpaul-bebirian.pixels.com/featured/df156-walter-paul-bebirian.html',
  },
];

function Team() {
  return (
    <>
      <h2 className="head-text">Volord's Sales Team</h2>
      <motion.div
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__team-portfolio"
      >
        {salesTeam.map((team, index) => (
          <div className="app__team-item app__flex" key={index}>
            <div className="app__team-img app__flex">
              <img src={team.img} alt="sales" />
              <motion.div
                whileHover={{ opacity: [0, 1] }}
                transition={{
                  duration: 0.25,
                  ease: 'easeInOut',
                  staggerChildren: 0.5,
                }}
                className="app__team-hover app__flex"
              >
                <a href={team.imgUrl} target="_blank" rel="noreferrer">
                  <motion.div
                    whileInView={{ scale: [0, 1] }}
                    whileHover={{ scale: [1, 0.9] }}
                    transition={{ duration: 0.25 }}
                    className="app__flex"
                  >
                    <AiFillEye />
                  </motion.div>
                </a>
              </motion.div>
            </div>
            <div className="app__team-content app__flex">
              <p className="p-text" style={{ marginTop: 10 }}>
                {team.title}
              </p>
            </div>
          </div>
        ))}
      </motion.div>
    </>
  );
}

export default AppWrap(
  MotionWrap(Team, 'team__container'),
  'team',
  'app__primary-bg',
);
