import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { motion } from 'framer-motion';

import { AppWrap, MotionWrap } from '../../wrapper';
import './About.scss';
import { urlFor, client } from '../../client';

const About = () => {
  const [abouts, setAbouts] = useState([]);
  const location = useLocation();

  const isHomePage = location.pathname === '/';

  useEffect(() => {
    const query = '*[_type == "abouts"]';

    client.fetch(query).then((data) => {
      setAbouts(data);
    });
  }, []);

  return (
    <>
      {isHomePage && (
        <h2 className="head-text">
          About
          <br />
          <span>Walter Paul Bebirian</span>
        </h2>
      )}
      {!isHomePage &&(
        <h2 className='head-text' style={{marginTop: "50px"}}>
             About
          <br />
          <span>Walter Paul Bebirian</span>
        </h2>
      )}

      <div className="app__profiles">
        {abouts.map((about, index) => (
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.04 }}
            transition={{ duration: 0.5, type: 'tween' }}
            className="app__profile-item"
            key={about.title + index}
          >
            <img src={urlFor(about.imgUrl)} alt={about.title} />
            <div className="app__profile-text">
              <h2 className="bold-text" style={{ marginTop: 0 }}>
                {about.title}
              </h2>
              <p className="p-text" style={{ marginTop: 10 }}>
                {about.description}
              </p>
            </div>
          </motion.div>
        ))}
      </div>
      <motion.div
        whileInView={{ opacity: 1 }}
        whileHover={{ scale: 1.04 }}
        transition={{ duration: 0.5, type: 'tween' }}
      >
        <a
          target="_blank"
          href="https://walterpaul-bebirian.pixels.com/art"
          style={{ textDecoration: 'none' }}
        >
          <button className="app__header-button" style={{marginTop: 40}} type="button">
            <span>Shop Now</span>
          </button>
        </a>
      </motion.div>
    </>
  );
};

export default AppWrap(
  MotionWrap(About, 'app__about'),
  'about',
  'app__whitebg',
);
