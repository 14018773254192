import React from "react";
import { About, Articles, Companies, Footer, Header, Shop, VirtualGalleries, Work, Money, Videos, Xmas, Team, AIgenerated } from '../container';
import '../App.scss';

const Home = () => {
  return (
    <>
      <div className="app">
        <a
          href="https://walterpaul-bebirian.pixels.com/featured/2-10-2012g-walter-paul-bebirian.html"
          target="_blank"
          className="background-link"
          style={{ textDecoration: 'none' }}
        >
          <Header />
        </a>
        <About />
        <Work />
        <VirtualGalleries />
        <Articles />
        <Companies />
        <Videos />
        <Money />
        <Shop />
        <Xmas />
        <Team />
        <AIgenerated />
        <Footer />
      </div>
    </>
  );
}

export default Home;