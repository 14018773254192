import React from 'react';
import { AppWrap, MotionWrap } from '../../wrapper';
import { FaYoutubeSquare, FaVimeoV } from 'react-icons/fa';
import { motion } from 'framer-motion';

import { useLocation } from 'react-router-dom';

import './Videos.scss';

const Videos = () => {
  const location = useLocation();

  return (
    <>
      <div>
        <h2 className="head-text videos">
          Videos from Walter Paul Bebirian
          <br />
          <span className="sub-heading-videos">
            Check out the latest videos from Walter Paul Bebirian
          </span>
        </h2>
        <div className="videos__container">
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.04 }}
            transition={{ duration: 0.5, type: 'tween' }}
            className="video-card"
          >
            <img
              src={require('../../assets/background8.jpg')}
              alt="Bebirian Art"
              className="video-card__image"
            />
            <a
              href="https://www.youtube.com/@bebirianart"
              target="_blank"
              className="video-card__link"
            >
              <FaYoutubeSquare className="video-card__icon" />
              Bebirian Art
            </a>
          </motion.div>
          '{' '}
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.04 }}
            transition={{ duration: 0.5, type: 'tween' }}
            className="video-card"
          >
            <img
              src={require('../../assets/bestSeller3.jpg')}
              alt="Volord Kingdom"
              className="video-card__image"
            />
            <a
              href="https://www.youtube.com/@VolordKingdom"
              target="_blank"
              className="video-card__link"
            >
              <FaYoutubeSquare className="video-card__icon" />
              Volord Kingdom
            </a>
          </motion.div>
          '
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.04 }}
            transition={{ duration: 0.5, type: 'tween' }}
            className="video-card"
          >
            <img
              src={require('../../assets/bestSeller1.jpg')}
              alt="Walter Paul Bebirian"
              className="video-card__image"
            />
            <a
              href="https://vimeo.com/user15553612"
              target="_blank"
              className="video-card__link"
            >
              <FaVimeoV className="video-card__icon" />
              Walter Paul Bebirian
            </a>
          </motion.div>
        </div>
        <h2 className="head-text blogs">
          <span className="sub-heading-blogs">
            The latest blogs from Walter Paul Bebirian
          </span>
        </h2>
        <div className="blogger__container">
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.04 }}
            transition={{ duration: 0.5, type: 'tween' }}
            className="blogger__card"
          >
            <img
              src={require('../../assets/money_6_crop.jpg')}
              alt="Bebirian Art Blogs"
              className="blogger__image"
            />
            <a
              href="https://www.blogger.com/profile/15974098797053338884"
              target="_blank"
              className="blogger__link"
            >
              Bebirian Art Blogger
            </a>
          </motion.div>
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.04 }}
            transition={{ duration: 0.5, type: 'tween' }}
            className="blogger__card"
          >
            <img
              src={require('../../assets/volord_kingdom_logo.jpg')}
              alt="Volord Kingdom Blogs"
              className="blogger__image"
            />
            <a
              href="https://www.blogger.com/profile/12297813609539109541"
              target="_blank"
              className="blogger__link"
            >
              Volord Blogger
            </a>
          </motion.div>
        </div>
        <motion.div
          whileInView={{ opacity: 1 }}
          whileHover={{ scale: 1.04 }}
          transition={{ duration: 0.5, type: 'tween' }}
          className="shop-now-container"
        >
          <a
            href="https://walterpaul-bebirian.pixels.com/art"
            target="_blank"
            className="shop-now-button"
          >
            Shop Now
          </a>
        </motion.div>
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Videos, 'videos__container'),
  'videos',
  'app__primary-bg',
);
