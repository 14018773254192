import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { images } from '../../constants';
import { AppWrap, MotionWrap } from '../../wrapper';
import { client } from '../../client';
import './Footer.scss';

const Footer = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const location = useLocation();

  const { username, email, message } = formData;

  const isHomePage = location.pathname === '/';

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = () => {
    setLoading(true);

    const contact = {
      _type: 'contact',
      name: formData.username,
      email: formData.email,
      message: formData.message,
    };

    client
      .create(contact)
      .then(() => {
        setLoading(false);
        setIsFormSubmitted(true);
      })
      .catch((err) => console.log(err));
  };

  return (
    <>
      {isHomePage && (
        <h2 className="head-text">Contact Me</h2>
      )}

      {!isHomePage && (
        <h2 style={{ paddingTop: '60px' }} className="head-text">
          Contact Me
        </h2>
      )}

      <div className="app__footer-cards">
        <div className="app__footer-card ">
          <img src={images.email} alt="email" />
          <a href="mailto:wprb1@yahoo.com" className="p-text">
            wprb1@yahoo.com
          </a>
        </div>
        <div className="app__footer-card">
          <img src={images.mobile} alt="phone" />
          <a href="tel:+1 (123) 456-7890" className="p-text">
            +1 (917) 456-7287
          </a>
        </div>
      </div>
      {!isFormSubmitted ? (
        <div className="app__footer-form app__flex">
          <div className="app__flex">
            <input
              className="p-text"
              type="text"
              placeholder="Your Name"
              name="username"
              value={username}
              onChange={handleChangeInput}
            />
          </div>
          <div className="app__flex">
            <input
              className="p-text"
              type="email"
              placeholder="Your Email"
              name="email"
              value={email}
              onChange={handleChangeInput}
            />
          </div>
          <div>
            <textarea
              className="p-text"
              placeholder="Your Message"
              value={message}
              name="message"
              onChange={handleChangeInput}
            />
          </div>
          <button type="button" className="p-text" onClick={handleSubmit}>
            {!loading ? 'Send Message' : 'Sending...'}
          </button>
        </div>
      ) : (
        <div>
          <h3 className="head-text">Thank you for getting in touch!</h3>
        </div>
      )}
      <div style={{marginTop: "20px"}}>Additional Links</div>
      <div className="app__footer-links">
        <a
          href="http://i000.us/"
          target="_blank"
          rel="noopener noreferrer"
          className='app__footer-link'
        >
          Volord Kingdom - main site
        </a>
        <a
          href="https://walterpaul-bebirian.pixels.com/"
          target="_blank"
          rel="noopener noreferrer"
          className='app__footer-link'
        >
          The Volord Kingdom Art Collection 
        </a>
        <a
          href="https://bebirian.faso.com/"
          target="_blank"
          rel="noopener noreferrer"
          className='app__footer-link'
        >
         Walter Paul Bebirian Fine Art
        </a>
        <a
          href="https://theideavirus.com/"
          target="_blank"
          rel="noopener noreferrer"
          className='app__footer-link'
        >
         The Idea Virus One Pager
        </a>
        
      </div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Footer, 'app__footer'),
  'contact',
  'app__whitebg',
);
