import React from 'react';
import { FaInstagram, FaFacebookF, FaMediumM } from 'react-icons/fa';
import { BsTwitterX } from 'react-icons/bs';

const SocialMedia = () => (
  <div className="app__social">
    <div>
      <a
        href="https://twitter.com/volordkingdom"
        target="_blank"
        rel="noreferrer"
      >
        <BsTwitterX />
      </a>
    </div>
    <div>
      <a
        href="https://www.facebook.com/walterpaulbebirian"
        target="_blank"
        rel="noreferrer"
      >
        <FaFacebookF />
      </a>
    </div>
    <div>
      <a
        href="https://www.instagram.com/volordkingdom/"
        target="_blank"
        rel="noreferrer"
      >
        <FaInstagram />
      </a>
    </div>
    <div>
      <a
        href="https://medium.com/@walterpaulbebirian"
        target="_blank"
        rel="noreferrer"
      >
        <FaMediumM />
      </a>
    </div>
  </div>
);

export default SocialMedia;
