import email from '../assets/email.png';
import mobile from '../assets/mobile.png';

import about01 from '../assets/about01.png';
import about02 from '../assets/about02.png';
import about03 from '../assets/about03.png';
import about04 from '../assets/about04.png';

import circle from '../assets/circle.svg';
import logo from '../assets/Volord Logo lg.png';
import ibm from '../assets/IBM_logo_in.jpg';
import ryze from '../assets/ryze.png';
import collweb from '../assets/collweb_logo.jpeg';
import saatchi from '../assets/Saatchi_Art_logo.png';
import volord from '../assets/volord_kingdom_logo.jpg';
import verifiedArtist from '../assets/artistsinfo.jpeg';
import paypal from '../assets/paypal_logo.jpg';
import twitter from '../assets/x_logo.png';
import artNet from '../assets/artnet_logo.jpg';
import facebook from '../assets/facebook_logo.png';
import linkedin from '../assets/linkedin_logo.png';
import instagram from '../assets/instagram_logo.jpeg';
import tumblr from '../assets/tumblr_logo.png';
import pinterest from '../assets/pinterest.png';
import sokule from '../assets/sokule_logo.jpg';




export default {
  email,
  mobile,
  about01,
  about02,
  about03,
  about04,
  circle,
  logo,
  ibm,
  ryze,
  collweb,
  saatchi,
  volord,
  verifiedArtist,
  paypal,
  twitter,
  artNet,
  facebook,
  linkedin,
  instagram,
  tumblr,
  pinterest,
  sokule
};
