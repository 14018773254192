import React from 'react';
import { AppWrap, MotionWrap } from '../../wrapper';
import { motion } from 'framer-motion';

import { useLocation } from 'react-router-dom';

import './Shop.scss';

const Shop = () => {
  const location = useLocation();

  const isHomePage = location.pathname === '/';

  return (
    <>
      <h2 className="head-text">
        VOLORD KINGDOM ART COLLECTION
        <br />
        <span className="sub-heading-shop" style={{ fontSize: '20px' }}>
          Purchase images on different products! 30-day money-back guarantee
        </span>
      </h2>
      <div className="shop__container">
        <motion.div
          whileInView={{ opacity: 1 }}
          whileHover={{ scale: 1.04 }}
          transition={{ duration: 0.5, type: 'tween' }}
          className="header-shop-now-btn p-text"
        >
          <a
            href="https://walterpaul-bebirian.pixels.com/"
            target="_blank"
            className="shop_images"
          >
            <img
              src={require('../../assets/volord_kingdom.jpg')}
              alt="Volord Kingdom Logo"
            />
          </a>
        </motion.div>
        <motion.div
          whileInView={{ opacity: 1 }}
          whileHover={{ scale: 1.04 }}
          transition={{ duration: 0.5, type: 'tween' }}
          className="header-shop-now-btn p-text"
          style={{ paddingTop: 50 }}
        >
          <a
            href="https://walterpaul-bebirian.pixels.com/"
            target="_blank"
            className="shop_images"
          >
            <img
              src={require('../../assets/volord_kingdom_business_card.jpg')}
              alt="Volord Kingdom Logo"
            />
          </a>
        </motion.div>
      </div>
      <motion.div
        whileInView={{ opacity: 1 }}
        whileHover={{ scale: 1.04 }}
        transition={{ duration: 0.5, type: 'tween' }}
      >
        <a
          target="_blank"
          href="https://walterpaul-bebirian.pixels.com/art"
          style={{ textDecoration: 'none' }}
        >
          <button className="app__header-button" type="button">
            <span>Shop Now</span>
          </button>
        </a>
      </motion.div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Shop, 'shop__container'),
  'shop',
  'app__primary-bg',
);
