import React from "react";
import { Gallery, Footer, Shop, Money, VirtualGalleries } from '../container';
import '../App.scss';

const GalleryPage = () => {
  return (
    <>
      <div className="app">
        <Gallery />
        <VirtualGalleries />
        <Money />
        <Shop />
        <Footer />
      </div>
    </>
  );
}

export default GalleryPage;