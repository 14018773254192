import React from "react";
import { Shop, Money, Footer } from '../container';
import '../App.scss';

const ShopPage = () => {

  return (
    <>
      <div className="app">
        <Shop />
        <Money />
        <Footer />
      </div>
    </>
  );
}

export default ShopPage;