import React from 'react';
import Globe from 'react-globe.gl';
import { motion } from 'framer-motion';
import './Globe.scss';

const GlobeComponent = () => {
  const markers = [
    {
      lat: 38.9072,
      lng: -77.0369,
      city: 'Washington, D.C.',
      link: 'http://www.walterpaul-bebirian.pixels.com/',
      country: 'USA',
    },
    {
      lat: 56.1304,
      lng: -106.3468,
      city: 'Canada (English)',
      link: 'https://walterpaul-bebirian.pixels.com/featured/9-23-3057b-walter-paul-bebirian.html',
      country: 'Canada',
    },
    {
      lat: 45.5019,
      lng: -73.5674,
      city: 'Canada (French)',
      link: 'https://walterpaul-bebirian.pixels.com/featured/9-11-3057r-walter-paul-bebirian.html',
      country: 'Canada',
    },
    // Argentina
    {
      lat: -34.6037,
      lng: -58.3816,
      city: 'Buenos Aires',
      link: 'https://walterpaul-bebirian.pixels.com/featured/4-18-2015cabcdefghijklmnopqrtuv-walter-paul-bebirian.html',
      country: 'Argentina',
    },
    // Bahamas
    {
      lat: 25.0343,
      lng: -77.3963,
      city: 'Nassau',
      link: 'https://walterpaul-bebirian.pixels.com/featured/4-17-2015abcdefghijklm-walter-paul-bebirian.html',
      country: 'Bahamas',
    },
    // Belize
    {
      lat: 17.5046,
      lng: -88.1962,
      city: 'Belize City',
      link: 'https://walterpaul-bebirian.pixels.com/featured/4-12-2015cabc-walter-paul-bebirian.html',
      country: 'Belize',
    },
    // Bolivia
    {
      lat: -16.5,
      lng: -68.15,
      city: 'La Paz',
      link: 'https://walterpaul-bebirian.pixels.com/featured/4-12-2015abcdefghi-walter-paul-bebirian.html',
      country: 'Bolivia',
    },
    // Brazil
    {
      lat: -23.5505,
      lng: -46.6333,
      city: 'São Paulo',
      link: 'https://walterpaul-bebirian.pixels.com/featured/4-8-2015l-walter-paul-bebirian.html',
      country: 'Brazil',
    },
    // Caribbean (e.g., Kingston, Jamaica)
    {
      lat: 18.0179,
      lng: -76.8099,
      city: 'Kingston',
      link: 'https://walterpaul-bebirian.pixels.com/featured/10-19-2057a-walter-paul-bebirian.html',
      country: 'Jamaica',
    },
    // Chile
    {
      lat: -33.4489,
      lng: -70.6693,
      city: 'Santiago',
      link: 'https://walterpaul-bebirian.pixels.com/featured/4-3-2055mabcdefghijklmnopqrtuvwxyzabcdefghijkl-walter-paul-bebirian.html',
      country: 'Chile',
    },
    // Colombia
    {
      lat: 4.711,
      lng: -74.0721,
      city: 'Bogotá',
      link: 'https://walterpaul-bebirian.pixels.com/featured/3-28-2015dabcdefghijklmnopqrtuvwxyzabcdef-walter-paul-bebirian.html',
      country: 'Colombia',
    },
    // Costa Rica
    {
      lat: 9.9281,
      lng: -84.0907,
      city: 'San José',
      link: 'https://walterpaul-bebirian.pixels.com/featured/3-24-2015dabcdefghijklmnopqrtuvw-walter-paul-bebirian.html',
      country: 'Costa Rica',
    },
    // Dominican Republic
    {
      lat: 18.4861,
      lng: -69.9312,
      city: 'Santo Domingo',
      link: 'https://walterpaul-bebirian.pixels.com/featured/3-23-2015dabcdefghijklm-walter-paul-bebirian.html',
      country: 'Dominican Republic',
    },
    // Ecuador
    {
      lat: -0.1807,
      lng: -78.4678,
      city: 'Quito',
      link: 'https://walterpaul-bebirian.pixels.com/featured/3-23-2015babcd-walter-paul-bebirian.html',
      country: 'Ecuador',
    },
    // El Salvador
    {
      lat: 13.6929,
      lng: -89.2182,
      city: 'San Salvador',
      link: 'https://walterpaul-bebirian.pixels.com/featured/3-12-2015eabcdefghijklmnopqrtuvwxyzabcdefghi-walter-paul-bebirian.html',
      country: 'El Salvador',
    },
    // Guatemala
    {
      lat: 14.6349,
      lng: -90.5069,
      city: 'Guatemala City',
      link: 'https://walterpaul-bebirian.pixels.com/featured/3-10-2015abcdefghijklmnopqrtuvwxy-walter-paul-bebirian.html',
      country: 'Guatemala',
    },
    // Honduras
    {
      lat: 14.0723,
      lng: -87.1921,
      city: 'Tegucigalpa',
      link: 'https://walterpaul-bebirian.pixels.com/featured/3-1-2015cabcdefghijklmn-walter-paul-bebirian.html',
      country: 'Honduras',
    },
    // Mexico
    {
      lat: 19.4326,
      lng: -99.1332,
      city: 'Mexico City',
      link: 'https://walterpaul-bebirian.pixels.com/featured/1-27-2015fabcde-walter-paul-bebirian.html',
      country: 'Mexico',
    },
    // Nicaragua
    {
      lat: 12.1364,
      lng: -86.2514,
      city: 'Managua',
      link: 'https://walterpaul-bebirian.pixels.com/featured/4-29-2015babcdefghijklmnopqrtuvwxyzabcdefghi-walter-paul-bebirian.html',
      country: 'Nicaragua',
    },
    // Panama
    {
      lat: 8.9824,
      lng: -79.5199,
      city: 'Panama City',
      link: 'https://walterpaul-bebirian.pixels.com/featured/4-29-2015b-walter-paul-bebirian.html',
      country: 'Panama',
    },
    // Paraguay
    {
      lat: -25.2637,
      lng: -57.5759,
      city: 'Asunción',
      link: 'https://walterpaul-bebirian.pixels.com/featured/4-22-2015fabcdefghijklmnopqrtuvwxyzabcde-walter-paul-bebirian.html',
      country: 'Paraguay',
    },
    // Peru
    {
      lat: -12.0464,
      lng: -77.0428,
      city: 'Lima',
      link: 'https://walterpaul-bebirian.pixels.com/featured/4-19-2015babcdefghijklmnopqrtuv-walter-paul-bebirian.html',
      country: 'Peru',
    },
    // Trinidad and Tobago
    {
      lat: 10.6918,
      lng: -61.2225,
      city: 'Port of Spain',
      link: 'https://walterpaul-bebirian.pixels.com/featured/4-18-2015babcdefghijkl-walter-paul-bebirian.html',
      country: 'Trinidad and Tobago',
    },
    // Uruguay
    {
      lat: -34.9011,
      lng: -56.1645,
      city: 'Montevideo',
      link: 'https://walterpaul-bebirian.pixels.com/featured/4-4-2015abc-walter-paul-bebirian.html',
      country: 'Uruguay',
    },
    // Venezuela
    {
      lat: 10.4806,
      lng: -66.9036,
      city: 'Caracas',
      link: 'https://walterpaul-bebirian.pixels.com/featured/3-29-2015dabcdefghijklmnopqrtuvwxyzabcdefg-walter-paul-bebirian.html',
      country: 'Venezuela',
    },
    // Austria
    {
      lat: 48.2082,
      lng: 16.3738,
      city: 'Vienna',
      link: 'https://walterpaul-bebirian.pixels.com/featured/3-20-2015eabcdefghijklmnopqrtuvwx-walter-paul-bebirian.html',
      country: 'Austria',
    },
    // Belgium (Brussels)
    {
      lat: 50.8503,
      lng: 4.3517,
      city: 'Brussels',
      link: 'https://walterpaul-bebirian.pixels.com/featured/3-17-2015kabcd-walter-paul-bebirian.html',
      country: 'Belgium',
    },
    // Bosnia and Herzegovina
    {
      lat: 43.8563,
      lng: 18.4131,
      city: 'Sarajevo',
      link: 'https://walterpaul-bebirian.pixels.com/featured/3-16-2015eabcdefghijklmnopqrtuvwxyzabcdefghij-walter-paul-bebirian.html',
      country: 'Romania',
    },
    // Bulgaria
    {
      lat: 42.6977,
      lng: 23.3219,
      city: 'Sofia',
      link: 'https://walterpaul-bebirian.pixels.com/featured/3-15-2015oabcdefghijklmnopqrtuvwxyza-walter-paul-bebirian.html',
      country: 'Bulgaria',
    },
    // Croatia
    {
      lat: 45.815,
      lng: 15.9819,
      city: 'Zagreb',
      link: 'https://walterpaul-bebirian.pixels.com/featured/3-15-2015habcdefghijklmnopq-walter-paul-bebirian.html',
      country: 'Croatia',
    },
    // Czech Republic
    {
      lat: 50.0755,
      lng: 14.4378,
      city: 'Prague',
      link: 'https://walterpaul-bebirian.pixels.com/featured/3-15-2015dabcdefgh-walter-paul-bebirian.html',
      country: 'Czech Republic',
    },
    // Denmark
    {
      lat: 55.6761,
      lng: 12.5683,
      city: 'Copenhagen',
      link: 'https://walterpaul-bebirian.pixels.com/featured/3-13-2015rabcdefghijklmnopqrtuvwxyzabcdefghijklmno-walter-paul-bebirian.html',
      country: 'Denmark',
    },
    // Estonia
    {
      lat: 59.437,
      lng: 24.7535,
      city: 'Tallinn',
      link: 'https://walterpaul-bebirian.pixels.com/featured/3-13-2015kabcdefghijklmnopqrtuvwxyzabcdef-walter-paul-bebirian.html',
      country: 'Estonia',
    },
    // Finland
    {
      lat: 60.1695,
      lng: 24.9354,
      city: 'Helsinki',
      link: 'https://walterpaul-bebirian.pixels.com/featured/3-10-2015dabcdefghijklmnopqrtuvwx-walter-paul-bebirian.html',
      country: 'Finland',
    },
    // France
    {
      lat: 48.8566,
      lng: 2.3522,
      city: 'Paris',
      link: 'https://walterpaul-bebirian.pixels.com/featured/3-9-2015dabcdefghijklmn-walter-paul-bebirian.html',
      country: 'France',
    },
    // Germany
    {
      lat: 52.52,
      lng: 13.405,
      city: 'Berlin',
      link: 'https://walterpaul-bebirian.pixels.com/featured/3-8-2015abcdef-walter-paul-bebirian.html',
      country: 'Germany',
    },
    // Great Britain
    {
      lat: 51.5074,
      lng: -0.1278,
      city: 'London',
      link: 'https://walterpaul-bebirian.pixels.com/featured/3-1-2015abcdefghijklmnopqrtuvwxyzabcdefghi-walter-paul-bebirian.html',
      country: 'Great Britain',
    },
    // Greece
    {
      lat: 37.9838,
      lng: 23.7275,
      city: 'Athens',
      link: 'https://walterpaul-bebirian.pixels.com/featured/1-1-31-2015cabcdefghijklmnopqrtuvwxyz-walter-paul-bebirian.html',
      country: 'Greece',
    },
    // Hungary
    {
      lat: 47.4979,
      lng: 19.0402,
      city: 'Budapest',
      link: 'https://walterpaul-bebirian.pixels.com/featured/12-30-2015gabcdefghijklmnopqr-walter-paul-bebirian.html',
      country: 'Hungary',
    },
    // Iceland
    {
      lat: 64.1355,
      lng: -21.8954,
      city: 'Reykjavik',
      link: 'https://walterpaul-bebirian.pixels.com/featured/12-30-2015eabcdefghij-walter-paul-bebirian.html',
      country: 'Iceland',
    },
    // Ireland
    {
      lat: 53.3498,
      lng: -6.2603,
      city: 'Dublin',
      link: 'https://walterpaul-bebirian.pixels.com/featured/12-30-2015dabcde-walter-paul-bebirian.html',
      country: 'Ireland',
    },
    // Italy
    {
      lat: 41.9028,
      lng: 12.4964,
      city: 'Rome',
      link: 'https://walterpaul-bebirian.pixels.com/featured/12-3-2015eabcdefghijklmnopqrtuvwxyzabcdefghijkl-walter-paul-bebirian.html',
      country: 'Italy',
    },
    // Latvia
    {
      lat: 56.9496,
      lng: 24.1052,
      city: 'Riga',
      link: 'https://walterpaul-bebirian.pixels.com/featured/11-29-2015gabcdefghijklmnopqrtuvwxyzabcde-walter-paul-bebirian.html',
      country: 'Latvia',
    },
    // Lithuania
    {
      lat: 54.6872,
      lng: 25.2797,
      city: 'Vilnius',
      link: 'https://walterpaul-bebirian.pixels.com/featured/11-29-2015cabcdefghijklmnopqrtuv-walter-paul-bebirian.html',
      country: 'Lithuania',
    },
    // Luxembourg
    {
      lat: 49.6117,
      lng: 6.1319,
      city: 'Luxembourg City',
      link: 'https://walterpaul-bebirian.pixels.com/featured/11-29-2015abcdefghijklmn-walter-paul-bebirian.html',
      country: 'Luxembourg',
    },
    // Netherlands
    {
      lat: 52.3676,
      lng: 4.9041,
      city: 'Amsterdam',
      link: 'https://walterpaul-bebirian.pixels.com/featured/11-25-2015eabcd-walter-paul-bebirian.html',
      country: 'Netherlands',
    },
    // Norway
    {
      lat: 59.9139,
      lng: 10.7522,
      city: 'Oslo',
      link: 'https://walterpaul-bebirian.pixels.com/featured/11-22-2015cabcdefghijklmnopqrtuvwxyzabcdefghi-walter-paul-bebirian.html',
      country: 'Norway',
    },
    // Poland
    {
      lat: 52.2297,
      lng: 21.0122,
      city: 'Warsaw',
      link: 'https://walterpaul-bebirian.pixels.com/featured/11-20-2015dabcdefghijklmnopqrtuvwxyz-walter-paul-bebirian.html',
      country: 'Poland',
    },
    // Portugal
    {
      lat: 38.7223,
      lng: -9.1393,
      city: 'Lisbon',
      link: 'https://walterpaul-bebirian.pixels.com/featured/11-15-2015abcdefghijklmnop-walter-paul-bebirian.html',
      country: 'Portugal',
    },
    // Romania
    {
      lat: 44.4268,
      lng: 26.1025,
      city: 'Bucharest',
      link: 'https://walterpaul-bebirian.pixels.com/featured/11-11-2015abcdefghijk-walter-paul-bebirian.html',
      country: 'Romania',
    },
    // Serbia
    {
      lat: 44.7866,
      lng: 20.4489,
      city: 'Belgrade',
      link: 'https://walterpaul-bebirian.pixels.com/featured/11-9-2015cabc-walter-paul-bebirian.html',
      country: 'Serbia',
    },
    // Slovakia
    {
      lat: 48.1486,
      lng: 17.1077,
      city: 'Bratislava',
      link: 'https://walterpaul-bebirian.pixels.com/featured/11-7-2015babcdefghijklmnopqrtuvwxyzabcdefgh-walter-paul-bebirian.html',
      country: 'Slovakia',
    },
    // Slovenia
    {
      lat: 46.0569,
      lng: 14.5058,
      city: 'Ljubljana',
      link: 'https://walterpaul-bebirian.pixels.com/featured/11-7-2015abcdefghijklmnopqrtuvwxy-walter-paul-bebirian.html',
      country: 'Slovenia',
    },
    // Spain
    {
      lat: 40.4168,
      lng: -3.7038,
      city: 'Madrid',
      link: 'https://walterpaul-bebirian.pixels.com/featured/11-6-2015habcdefghijklmn-walter-paul-bebirian.html',
      country: 'Spain',
    },
    // Sweden
    {
      lat: 59.3293,
      lng: 18.0686,
      city: 'Stockholm',
      link: 'https://walterpaul-bebirian.pixels.com/featured/11-5-2015eabcde-walter-paul-bebirian.html',
      country: 'Sweden',
    },
    // Switzerland
    {
      lat: 47.3769,
      lng: 8.5417,
      city: 'Zurich',
      link: 'https://walterpaul-bebirian.pixels.com/featured/10-20-2015mabcdefghijklmnopqrtuvwxyzabc-walter-paul-bebirian.html',
      country: 'Switzerland',
    },
    // Vatican City
    {
      lat: 41.9029,
      lng: 12.4534,
      city: 'Vatican City',
      link: 'https://fineartamerica.com/featured/christ-walter-paul-bebirian.html',
      country: 'Vatican City',
    },
    // Armenia
    {
      lat: 40.1792,
      lng: 44.4991,
      city: 'Yerevan',
      link: 'https://walterpaul-bebirian.pixels.com/featured/10-20-2015habcdefghijklmnopqrt-walter-paul-bebirian.html',
      country: 'Armenia',
    },
    // Azerbaijan
    {
      lat: 40.4093,
      lng: 49.8671,
      city: 'Baku',
      link: 'https://walterpaul-bebirian.pixels.com/featured/10-20-2015eabcdefghij-walter-paul-bebirian.html',
      country: 'Azerbaijan',
    },
    // Bahrain
    {
      lat: 26.2285,
      lng: 50.586,
      city: 'Manama',
      link: 'https://walterpaul-bebirian.pixels.com/featured/10-15-2015ba-walter-paul-bebirian.html',
      country: 'Bahrain',
    },
    // Belarus
    {
      lat: 53.9045,
      lng: 27.5615,
      city: 'Minsk',
      link: 'https://walterpaul-bebirian.pixels.com/featured/6-3-2015babcdefghijklmnopqrtuvwxyzabcdefghijklm-walter-paul-bebirian.html',
      country: 'Belarus',
    },
    // Georgia
    {
      lat: 41.7151,
      lng: 44.8271,
      city: 'Tbilisi',
      link: 'https://walterpaul-bebirian.pixels.com/featured/5-29-2016babcdefghijklmnopqrtuvwxyzabcdef-walter-paul-bebirian.html',
      country: 'Georgia',
    },
    // India
    {
      lat: 28.6139,
      lng: 77.209,
      city: 'New Delhi',
      link: 'https://walterpaul-bebirian.pixels.com/featured/5-16-2016abcdefghijklmnopqrtuvw-walter-paul-bebirian.html',
      country: 'India',
    },
    // Israel
    {
      lat: 31.7683,
      lng: 35.2137,
      city: 'Jerusalem',
      link: 'https://walterpaul-bebirian.pixels.com/featured/5-6-2016babcdefghijklm-walter-paul-bebirian.html',
      country: 'Israel',
    },
    // Kazakhstan
    {
      lat: 51.1694,
      lng: 71.4491,
      city: 'Astana',
      link: 'https://walterpaul-bebirian.pixels.com/featured/3-26-2016cabcdefghijklmnopqrtuvwxyzabcdefgh-walter-paul-bebirian.html',
      country: 'Kazakhstan',
    },
    // Pakistan
    {
      lat: 33.6844,
      lng: 73.0479,
      city: 'Islamabad',
      link: 'https://walterpaul-bebirian.pixels.com/featured/3-21-2016abcdefghijklmnopqrtuvwxyz-walter-paul-bebirian.html',
      country: 'Pakistan',
    },
    // Palestinian Territories
    {
      lat: 31.9474,
      lng: 35.2272,
      city: 'Ramallah',
      link: 'https://walterpaul-bebirian.pixels.com/featured/3-14-2016eabcdefghijklmnop-walter-paul-bebirian.html',
      country: 'Palestinian Territories',
    },
    // Qatar
    {
      lat: 25.276,
      lng: 51.2265,
      city: 'Doha',
      link: 'https://walterpaul-bebirian.pixels.com/featured/3-11-2016fabcdefghij-walter-paul-bebirian.html',
      country: 'Qatar',
    },
    // Russia
    {
      lat: 55.7558,
      lng: 37.6173,
      city: 'Moscow',
      link: 'https://walterpaul-bebirian.pixels.com/featured/3-11-2016ea-walter-paul-bebirian.html',
      country: 'Russia',
    },
    // Turkey
    {
      lat: 41.0082,
      lng: 28.9784,
      city: 'Istanbul',
      link: 'https://walterpaul-bebirian.pixels.com/featured/3-9-2016abcdefghijklmnopqrtuvwxyzabcde-walter-paul-bebirian.html',
      country: 'Turkey',
    },
    // Ukraine
    {
      lat: 50.4501,
      lng: 30.5234,
      city: 'Kyiv',
      link: 'https://walterpaul-bebirian.pixels.com/featured/3-8-2016babcdefghijklmnopqrtuv-walter-paul-bebirian.html',
      country: 'Ukraine',
    },
    // United Arab Emirates
    {
      lat: 25.2048,
      lng: 55.2708,
      city: 'Dubai',
      link: 'https://walterpaul-bebirian.pixels.com/featured/3-6-2016abcdefghijklm-walter-paul-bebirian.html',
      country: 'United Arab Emirates',
    },
    // Algeria
    {
      lat: 36.7372,
      lng: 3.0866,
      city: 'Algiers',
      link: 'https://walterpaul-bebirian.pixels.com/featured/3-3-2016dabc-walter-paul-bebirian.html',
      country: 'Algeria',
    },
    // Angola
    {
      lat: -8.839,
      lng: 13.2894,
      city: 'Luanda',
      link: 'https://walterpaul-bebirian.pixels.com/featured/3-1-2016babcdefghijklmnopqrtuvwx-walter-paul-bebirian.html',
      country: 'Angola',
    },
    // Botswana
    {
      lat: -24.6282,
      lng: 25.9231,
      city: 'Gaborone',
      link: 'https://walterpaul-bebirian.pixels.com/featured/3-1-2016abcdefghijklmn-walter-paul-bebirian.html',
      country: 'Botswana',
    },
    // Congo
    {
      lat: -4.2634,
      lng: 15.2429,
      city: 'Kinshasa',
      link: 'https://walterpaul-bebirian.pixels.com/featured/2-29-2016abcdef-walter-paul-bebirian.html',
      country: 'Congo',
    },
    // Democratic Republic of Congo
    {
      lat: -4.4419,
      lng: 15.2663,
      city: 'Brazzaville',
      link: 'https://walterpaul-bebirian.pixels.com/featured/2-26-2016babcdefghijklmnopqrtuvwxyzabcdefghij-walter-paul-bebirian.html',
      country: 'Democratic Republic of Congo',
    },
    // Djibouti
    {
      lat: 11.8251,
      lng: 42.5903,
      city: 'Djibouti',
      link: 'https://walterpaul-bebirian.pixels.com/featured/2-25-2016dabcdefghijklmnopqrtuvwxyzabc-walter-paul-bebirian.html',
      country: 'Djibouti',
    },
    // Egypt
    {
      lat: 30.0444,
      lng: 31.2357,
      city: 'Cairo',
      link: 'https://walterpaul-bebirian.pixels.com/featured/2-23-2016habcdefghij-walter-paul-bebirian.html',
      country: 'Egypt',
    },
    // Ethiopia
    {
      lat: 9.03,
      lng: 38.74,
      city: 'Addis Ababa',
      link: 'https://walterpaul-bebirian.pixels.com/featured/2-23-2016eab-walter-paul-bebirian.html',
      country: 'Ethiopia',
    },
    // Kenya
    {
      lat: -1.2864,
      lng: 36.8172,
      city: 'Nairobi',
      link: 'https://walterpaul-bebirian.pixels.com/featured/2-22-2016abcdefghijklmnopqrtuvwxyzabcdefghi-walter-paul-bebirian.html',
      country: 'Kenya',
    },
    // Lesotho
    {
      lat: -29.61,
      lng: 28.2336,
      city: 'Maseru',
      link: 'https://walterpaul-bebirian.pixels.com/featured/2-15-2016labcdefghijklmnopqrtuvwxyza-walter-paul-bebirian.html',
      country: 'Lesotho',
    },
    // Madagascar
    {
      lat: -18.8792,
      lng: 47.5079,
      city: 'Antananarivo',
      link: 'https://walterpaul-bebirian.pixels.com/featured/2-14-2016cabcdefghijklmnopqrt-walter-paul-bebirian.html',
      country: 'Madagascar',
    },
    // Malawi
    {
      lat: -13.9626,
      lng: 33.7741,
      city: 'Lilongwe',
      link: 'https://walterpaul-bebirian.pixels.com/featured/2-14-2016abcdefghij-walter-paul-bebirian.html',
      country: 'Malawi',
    },
    // Mauritius
    {
      lat: -20.1609,
      lng: 57.5012,
      city: 'Port Louis',
      link: 'https://walterpaul-bebirian.pixels.com/featured/2-12-2016ca-walter-paul-bebirian.html',
      country: 'Mauritius',
    },
    // Morocco
    {
      lat: 33.5731,
      lng: -7.5898,
      city: 'Casablanca',
      link: 'https://walterpaul-bebirian.pixels.com/featured/2-9-2016babcdefghijklmnopqrtuvwxyzabcdefgh-walter-paul-bebirian.html',
      country: 'Morocco',
    },
    // Mozambique
    {
      lat: -25.9692,
      lng: 32.5732,
      city: 'Maputo',
      link: 'https://walterpaul-bebirian.pixels.com/featured/2-4-2016gabcdefghijklmnopqrtuvwxy-walter-paul-bebirian.html',
      country: 'Mozambique',
    },
    // Namibia
    {
      lat: -22.5609,
      lng: 17.0658,
      city: 'Windhoek',
      link: 'https://walterpaul-bebirian.pixels.com/featured/2-4-2016fabcdefghijklmnopq-walter-paul-bebirian.html',
      country: 'Namibia',
    },
    // Nigeria
    {
      lat: 6.5244,
      lng: 3.3792,
      city: 'Lagos',
      link: 'https://walterpaul-bebirian.pixels.com/featured/2-4-2016dabcdefghij-walter-paul-bebirian.html',
      country: 'Nigeria',
    },
    // Rwanda
    {
      lat: -1.9706,
      lng: 30.1044,
      city: 'Kigali',
      link: 'https://walterpaul-bebirian.pixels.com/featured/2-4-2016cabc-walter-paul-bebirian.html',
      country: 'Rwanda',
    },
    // Somalia
    {
      lat: 2.0469,
      lng: 45.3182,
      city: 'Mogadishu',
      link: 'https://walterpaul-bebirian.pixels.com/featured/2-1-2016dabcdefghijklmnopqrtuvwxyzabcdefgh-walter-paul-bebirian.html',
      country: 'Somalia',
    },
    // South Africa
    {
      lat: -33.9249,
      lng: 18.4241,
      city: 'Cape Town',
      link: 'https://walterpaul-bebirian.pixels.com/featured/1-31-2016dabcdefghijklmnopqrtuvwxyz-walter-paul-bebirian.html',
      country: 'South Africa',
    },
    // Eswatini
    {
      lat: -26.5225,
      lng: 31.4659,
      city: 'Mbabane',
      link: 'https://walterpaul-bebirian.pixels.com/featured/1-31-2016abcdefghijklmnop-walter-paul-bebirian.html',
      country: 'Eswatini',
    },
    // Tanzania
    {
      lat: -6.7924,
      lng: 39.2083,
      city: 'Dar es Salaam',
      link: 'https://walterpaul-bebirian.pixels.com/featured/1-29-2016cabcdefg-walter-paul-bebirian.html',
      country: 'Tanzania',
    },
    // Tunisia
    {
      lat: 36.8065,
      lng: 10.1815,
      city: 'Tunis',
      link: 'https://walterpaul-bebirian.pixels.com/featured/1-6-2016c-walter-paul-bebirian.html',
      country: 'Tunisia',
    },
    // Uganda
    {
      lat: 0.3476,
      lng: 32.5825,
      city: 'Kampala',
      link: 'https://walterpaul-bebirian.pixels.com/featured/1-3-2016dabcdefghijklmnopqrtuvwxyzabcdefgh-walter-paul-bebirian.html',
      country: 'Uganda',
    },
    // Zambia
    {
      lat: -15.3875,
      lng: 28.3228,
      city: 'Lusaka',
      link: 'https://walterpaul-bebirian.pixels.com/featured/1-3-2016babcdefghijklmnopqrtuvwxy-walter-paul-bebirian.html',
      country: 'Zambia',
    },
    // Zimbabwe
    {
      lat: -17.8292,
      lng: 31.0522,
      city: 'Harare',
      link: 'https://walterpaul-bebirian.pixels.com/featured/1-2-2016cabcdefghijklmnop-walter-paul-bebirian.html',
      country: 'Zimbabwe',
    },
    // Australia
    {
      lat: -33.8688,
      lng: 151.2093,
      city: 'Sydney',
      link: 'https://walterpaul-bebirian.pixels.com/featured/1-1-2016babcdefgh-walter-paul-bebirian.html',
      country: 'Australia',
    },
    // Bangladesh
    {
      lat: 23.8103,
      lng: 90.4125,
      city: 'Dhaka',
      link: 'https://walterpaul-bebirian.pixels.com/featured/6-11-2016a-walter-paul-bebirian.html',
      country: 'Bangladesh',
    },
    // Hong Kong
    {
      lat: 22.3193,
      lng: 114.1694,
      city: 'Hong Kong',
      link: 'https://walterpaul-bebirian.pixels.com/featured/2-5-2016c-walter-paul-bebirian.html',
      country: 'Hong Kong',
    },
    // Indonesia
    {
      lat: -6.2088,
      lng: 106.8456,
      city: 'Jakarta',
      link: 'https://walterpaul-bebirian.pixels.com/featured/8-15-2016g-walter-paul-bebirian.html',
      country: 'Indonesia',
    },
    // Japan
    {
      lat: 35.6762,
      lng: 139.6503,
      city: 'Tokyo',
      link: 'https://walterpaul-bebirian.pixels.com/featured/5-20-2016b-walter-paul-bebirian.html',
      country: 'Japan',
    },
    // Malaysia
    {
      lat: 3.139,
      lng: 101.6869,
      city: 'Kuala Lumpur',
      link: 'https://walterpaul-bebirian.pixels.com/featured/1-18-2016e-walter-paul-bebirian.html',
      country: 'Malaysia',
    },
    // Maldives
    {
      lat: 4.1755,
      lng: 73.5093,
      city: 'Malé',
      link: 'https://walterpaul-bebirian.pixels.com/featured/7-29-2016d-walter-paul-bebirian.html',
      country: 'Maldives',
    },
    // New Zealand
    {
      lat: -36.8485,
      lng: 174.7633,
      city: 'Auckland',
      link: 'https://walterpaul-bebirian.pixels.com/featured/2-1-2016a-walter-paul-bebirian.html',
      country: 'New Zealand',
    },
    // China
    {
      lat: 39.9042,
      lng: 116.4074,
      city: 'Beijing',
      link: 'https://walterpaul-bebirian.pixels.com/featured/9-3-2015e-walter-paul-bebirian.html',
      country: 'China',
    },
    // Philippines
    {
      lat: 14.5995,
      lng: 120.9842,
      city: 'Manila',
      link: 'https://walterpaul-bebirian.pixels.com/featured/8-18-2016d-walter-paul-bebirian.html',
      country: 'Philippines',
    },
    // South Korea
    {
      lat: 37.5665,
      lng: 126.978,
      city: 'Seoul',
      link: 'https://walterpaul-bebirian.pixels.com/featured/4-28-2016b-walter-paul-bebirian.html',
      country: 'South Korea',
    },
    // Singapore
    {
      lat: 1.3521,
      lng: 103.8198,
      city: 'Singapore',
      link: 'https://walterpaul-bebirian.pixels.com/featured/3-3-2016abcdefghijklmnopqrtuvwxyzabcdefgh-walter-paul-bebirian.html',
      country: 'Singapore',
    },
    // Sri Lanka
    {
      lat: 6.9271,
      lng: 79.8612,
      city: 'Colombo',
      link: 'https://walterpaul-bebirian.pixels.com/featured/11-23-2016a-walter-paul-bebirian.html',
      country: 'Sri Lanka',
    },
    // Taiwan
    {
      lat: 25.033,
      lng: 121.5654,
      city: 'Taipei',
      link: 'https://walterpaul-bebirian.pixels.com/featured/8-30-2016n-walter-paul-bebirian.html',
      country: 'Taiwan',
    },
    // Thailand
    {
      lat: 13.7563,
      lng: 100.5018,
      city: 'Bangkok',
      link: 'https://walterpaul-bebirian.pixels.com/featured/11-9-2016n-walter-paul-bebirian.html',
      country: 'Thailand',
    },
    // Vietnam
    {
      lat: 21.0285,
      lng: 105.8542,
      city: 'Hanoi',
      link: 'https://walterpaul-bebirian.pixels.com/featured/12-29-2016g-walter-paul-bebirian.html',
      country: 'Vietnam',
    },
  ];
  const labelsData = markers.map((marker, index) => ({
    ...marker,
    id: `label-${index}`,
  }));

  return (
    <>
      <h2
        style={{ paddingTop: '150px', paddingBottom: '50px' }}
        className="head-text"
      >
        Volord Global Reach
      </h2>
      <div
        className="globe-container"
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <Globe
          globeImageUrl="//unpkg.com/three-globe/example/img/earth-blue-marble.jpg"
          animateIn={true}
          waitForGlobeReady={true}
          width={800}
          pointsData={markers}
          pointLat={(d) => d.lat}
          pointLng={(d) => d.lng}
          pointColor={() => 'red'}
          showAtmosphere={true}
          pointRadius={0.5}
          onPointClick={(marker) => window.open(marker.link, '_blank')}
          labelsData={labelsData}
          labelLat={(d) => d.lat}
          labelLng={(d) => d.lng}
          labelText={(d) => d.city}
          labelSize={1.0}
        />
      </div>
      <div
        className="app__navbar-button"
        style={{ marginTop: 50, justifyContent: 'center', paddingBottom: 50 }}
      >
        <a
          href="https://575488trillion.com/files/135923748.pdf"
          target="_blank"
        >
          <motion.button
            whileHover={{ scale: 1.1 }}
            whileTap={{ scale: 0.9 }}
            transition={{ duration: 0.3 }}
            className="header-shop-now-btn p-text"
            style={{
              fontWeight: 700,
              textTransform: 'uppercase',
              letterSpacing: '0.5px',
            }}
          >
            Look and Buy - A Message To The World About Volord Kingdom Art
            Collection
          </motion.button>
        </a>
      </div>
      <div className="globe-wrap">
        <div className="globe-row">
          <div className="globe-column">
            <h1 className="globe-heading">North America</h1>
            {markers.map((marker, index) => {
              if (index < 3) {
                return (
                  <ul>
                    <a className="globe-link" target="_blank" href={marker.link}>
                      <li>{marker.city}</li>
                    </a>
                  </ul>
                );
              }
            })}
          </div>
          <div className="globe-column">
            <h1 className="globe-heading">Latin America</h1>
            {markers.map((marker, index) => {
              if (index < 25 && index > 3) {
                return (
                  <ul>
                    <a className="globe-link" target="_blank" href={marker.link}>
                      <li>{marker.country}</li>
                    </a>
                  </ul>
                );
              }
            })}
          </div>
          <div className="globe-column">
            <h1 className="globe-heading">Europe</h1>
            {markers.map((marker, index) => {
              if (index < 57 && index > 25) {
                return (
                  <ul>
                    <a className="globe-link" target="_blank" href={marker.link}>
                      <li>{marker.country}</li>
                    </a>
                  </ul>
                );
              }
            })}
          </div>
          <div className="globe-column">
            <h1 className="globe-heading">Eurasia</h1>
            {markers.map((marker, index) => {
              if (index < 72 && index > 57) {
                return (
                  <ul>
                    <a className="globe-link"target="_blank" href={marker.link}>
                      <li>{marker.country}</li>
                    </a>
                  </ul>
                );
              }
            })}
          </div>
          <div className="globe-column">
            <h1 className="globe-heading">Africa</h1>
            {markers.map((marker, index) => {
              if (index < 98 && index > 71) {
                return (
                  <ul>
                    <a className="globe-link" target="_blank" href={marker.link}>
                      <li>{marker.country}</li>
                    </a>
                  </ul>
                );
              }
            })}
          </div>
          <div className="globe-column">
            <h1 className="globe-heading">Asia Pacific</h1>
            {markers.map((marker, index) => {
              if (index < 114 && index > 97) {
                return (
                  <ul>
                    <a className="globe-link" target="_blank" href={marker.link}>
                      <li>{marker.country}</li>
                    </a>
                  </ul>
                );
              }
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default GlobeComponent;
