import React, { useState, useEffect } from 'react';
import { AiFillEye } from 'react-icons/ai';
import { motion } from 'framer-motion';

import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './Articles.scss';

const Articles = () => {
  const [articles, setArticles] = useState([]);
  const [filterArticles, setFilterArticles] = useState([]);
  const [activeFilter, setActiveFilter] = useState('All');
  const [animateCard, setAnimateCard] = useState({ y: 0, opacity: 1 });

  useEffect(() => {
    const query = '*[_type == "articles"]';

    client.fetch(query).then((data) => {
      setArticles(data);
      setFilterArticles(data);
    });
  }, []);

  const handleArticleFilter = (item) => {
    setActiveFilter(item);
    setAnimateCard([{ y: 100, opacity: 0 }]);

    setTimeout(() => {
      setAnimateCard([{ y: 0, opacity: 1 }]);

      if (item === 'All') {
        setFilterArticles(articles);
      } else {
        setFilterArticles(
          articles.filter((article) => article.tags.includes(item)),
        );
      }
    }, 500);
  };

  return (
    <>
      <h2 className="head-text">Articles</h2>

      <div className="app__article-filter">
        {['Money + Art', 'Interview', 'Latest', 'All'].map((item, index) => (
          <div
            key={index}
            onClick={() => handleArticleFilter(item)}
            className={`app__article-filter-item app__flex p-text ${activeFilter === item ? 'item-active' : ''}`}
          >
            {item}
          </div>
        ))}
      </div>

      <motion.div
        animate={animateCard}
        transition={{ duration: 0.5, delayChildren: 0.5 }}
        className="app__article-portfolio"
      >
        {filterArticles.map((article, index) => (
          <div className="app__article-item app__flex" key={index}>
            <div className="app__article-img app__flex">
              <img src={urlFor(article.imgUrl)} alt={article.name} />

              <motion.div
                whileHover={{ opacity: [0, 1] }}
                transition={{
                  duration: 0.25,
                  ease: 'easeInOut',
                  staggerChildren: 0.5,
                }}
                className="app__article-hover app__flex"
              >
                <a href={article.articleLink} target="_blank" rel="noreferrer">
                  <motion.div
                    whileInView={{ scale: [0, 1] }}
                    whileHover={{ scale: [1, 0.9] }}
                    transition={{ duration: 0.25 }}
                    className="app__flex"
                  >
                    <AiFillEye />
                  </motion.div>
                </a>
              </motion.div>
            </div>

            <div className="app__article-content app__flex">
              <h4 className="bold-text">{article.title}</h4>
              <p className="p-text" style={{ marginTop: 10 }}>
                {article.description}
              </p>

              <div className="app__article-tag app__flex">
                <p className="p-text">{article.tags[0]}</p>
              </div>
            </div>
          </div>
        ))}
      </motion.div>
      <motion.div
        whileInView={{ opacity: 1 }}
        whileHover={{ scale: 1.04 }}
        transition={{ duration: 0.5, type: 'tween' }}
      >
        <a
          target="_blank"
          href="https://walterpaul-bebirian.pixels.com/art"
          style={{ textDecoration: 'none' }}
        >
          <button className="app__header-button" style={{marginTop: 40}} type="button">
            <span>Shop Now</span>
          </button>
        </a>
      </motion.div>
    </>
  );
};

export default AppWrap(
  MotionWrap(Articles, 'app__articles'),
  'articles',
  'app__primarybg',
);
