import React, { useState, useEffect } from 'react';
import { GlobeComponent, Network, Money, Footer } from '../container';
import '../App.scss';

const ContactPage = () => {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        // Cleanup event listener on component unmount
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div className='app'>
            {windowWidth > 900 && <GlobeComponent />}
            <Money />
            <Network />
            <Footer />
        </div>
    );
};

export default ContactPage;
