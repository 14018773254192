import React from "react";
import { About, Articles, Money, Footer, Shop } from '../container';
import '../App.scss';

const AboutPage = () => {
  return (
    <>
      <div className="app">
        <About />
        <Shop />
        <Money />
        <Articles />
        <Footer />
      </div>
    </>
  );
}

export default AboutPage;