import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';

import { AppWrap, MotionWrap } from '../../wrapper';
import { urlFor, client } from '../../client';
import './VirtualGalleries.scss';

const VirtualGalleries = () => {
  const [gallery, setGallery] = useState([]);

  useEffect(() => {
    const galleryQuery = '*[_type == "gallery"]';

    client.fetch(galleryQuery).then((data) => {
      setGallery(data);
    });
  }, []);

  return (
    <>
      <h2 className="head-text" style={{color: "white"}}>Virtual Gallery Exhibit</h2>
      <p style={{textAlign: 'center'}} className="virtual-p p-text">Immerse yourself in the art of Walter Paul Bebirian. Start the virtual tour below.</p>
      <div className="virtual-galleries-container">
        <motion.div className="virtual-galleries-list">
          {gallery.map((g, index) => (
            <motion.div
              whileInView={{ opacity: [0, 1] }}
              transition={{ duration: 0.5 }}
              className="virtual-galleries-item"
              key={g.name}
            >
              <div className="icon-container">
                <a href={g.url} target='_blank'>
                  <button className="gallery-button" alt={g.name}>{g.name}</button>
                </a>
              </div>
            </motion.div>
          ))}
        </motion.div>
      </div>
    </>
  );
  
};

export default AppWrap(
  MotionWrap(VirtualGalleries, 'app__virtual-galleries'),
  'virtual-galleries',
  'app__whitebg',
);
